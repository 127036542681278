.customScrollbar {
  &::-webkit-scrollbar {
    width: 0.5rem; // Adjust width as you like
    background-color: #F5F5F5; // Adjust background as you like
  }

  &::-webkit-scrollbar-thumb {
    background-color: #A6A6A6; // Adjust color as desired
    border-radius: 0.25rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #909090; // Adjust hover color if desired
  }

  // For Firefox
  scrollbar-width: thin;
  scrollbar-color: #A6A6A6 #F5F5F5; // Adjust to the thumb and track color you desire
}
