.location_filter_block {
  width: 100%;

  .location_list {
    li {
      font-size: 13px;
    }
  }
}

.fixed_height {
  max-height: 250px;
  overflow-y: scroll;
}

@media (min-width: 1280px) {
  .location_filter_block {
    width: 220px;
    border-right: 1px solid rgba(65, 80, 197, 0.15);
  }
}
