.check__label {
  input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    display: block;
  }
}

.checkbox__text {
  position: relative;
  padding: 0 0 0 25px;
  cursor: pointer;
}
.checkbox__text:before {
  content: '';
  position: absolute;
  top: 3px;
  left: 10px;
  width: 10px;
  height: 10px;
  border: 1px solid grey;
  border-radius: 2px;
  background: #ffffff;
  transition: 0.2s;
}
.checkbox__text:after {
  content: '';
}
.checkbox input:checked + .checkbox__text:before {
  background-image: url(../../../../../../../public/images/Tick-Mark.svg);
}
.checkbox input:checked + .checkbox__text:after {
  left: 26px;
}

.arrow_block {
  &:hover {
    cursor: pointer;
  }

  &.open {
    transform: rotate(180deg);

    .arrow::after {
      background-color: #28282e;
    }

    .arrow::before {
      background-color: #28282e;
    }
  }

  .arrow::after {
    content: '';
    position: absolute;
    top: 6px;
    right: 4px;
    width: 6px;
    height: 2px;
    background-color: #132c52;
    transform: rotate(40deg);
  }
  .arrow::before {
    content: '';
    position: absolute;
    top: 6px;
    right: 0;
    width: 6px;
    height: 2px;
    background-color: #132c52;
    transform: rotate(140deg);
  }
}

.state_checkbox {
  width: 14px;
  height: 14px;
  margin-top: 5px;
  left: 4px;
}

.location_sub_list {
  margin-top: 2px;
  margin-left: 10px;
  .checkbox {
    display: flex;
  }
  li {
    font-size: 12px;
  }
}
