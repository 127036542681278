
.product_description {
  ol,
  ul {
    margin-left: 2rem;
    margin-top: 0.5rem;
    li {
      margin-bottom: 0.25rem;
    }
  }
  ol {
    list-style: decimal;
  }
  ul {
    list-style: disc;
  }
}

.main_container {
  min-height: 80vh;
}

.fadeMe {
  opacity:    0.5;
  background: #000;
  width:      100%;
  height:     100%;
  z-index:    10;
  top:        0;
  left:       0;
  position:   fixed;
}


@media (max-width: 768px) {
  .main_container {
    min-height: 60vh;
  }
}
